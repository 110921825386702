<template>
  <div class="reserve-form-wrap">
    <!-- 예약입력  -->
    <div class="reserve-form-wrap--info">
      <div class="reserve-form-wrap--top">
        <p class="reserve-form-wrap--title"><strong>휴대전화 번호</strong>를<br>입력해주세요.</p>
        <p class="reserve-form-wrap--text">
          방문예약하신 고객님은<br/>
          <strong>예약하신 휴대전화번호로 방문등록</strong>을 하고<br/>
          안내에 따라 입장하여 주시기 바랍니다.
        </p>
      </div>
      <div class="reserve-form-wrap--sub">
        <div class="reserve-form-wrap--sub-kakao">
          방문등록하고 <strong>카카오톡</strong>으로 확인하세요
        </div>
      </div>

    </div>

    <!-- 완료 -->
    <div class="reserve-form-wrap--terms">
      <div class="reserve-form-wrap--terms--done">
        <div class="reserve-form-wrap--terms--done-name">{{ USER_NAME }} 님</div>
        <p class="reserve-form-wrap--terms--done-txt">
          방문예약 정보를확인했습니다.<br>고객님의 방문을 환영합니다.
        </p>
        <p class="reserve-form-wrap--terms--done-subtxt">
          아래 확인버튼을 누르고 방문등록을 완료해주세요.<br> 모바일로 다양한 분양정보를 받으실 수 있습니다.<br> <strong>안내에 따라 입장하여 주시기 바랍니다.</strong>
        </p>
      </div>


      <div class="reserve-form-wrap--terms-btn">
        <button type="button" class="btn-keypad-del"><span class="hide">del</span></button>
        <button type="button" class="btn-keypad-in">입력</button>
      </div>

    </div>
  </div>
  </template>

  <script>
    export default {
      name: 'CAP0580',
      components: {},
      props: {
      },
      data() {
        return {
          USER_NAME: '김길*'

        }
      },
      mounted() {},
      computed: {},
      methods: {

      }
    }
  </script>

  <style>
  </style>